
import { CONVERSATIONS_NAV_ITEM, NOTIFICATIONS_NAV_ITEM } from "@models/constants/navigation";
import NavItem from "./item.vue";
import NavUser from "./nav-user.vue";
import NavSearch from "./nav-search.vue";
import NotificationsPopup from "@components/notifications/popup.vue";
import { getModule } from "vuex-module-decorators";
import { HeaderStoreModule } from "./header.store";
import { Emitter } from "@utils/events";
import { computed, defineComponent, nextTick, onBeforeMount, watch } from "vue";
import { useConversations } from "@composables/conversations";
import { useNotifications } from "@composables/notifications";
import { viewportService } from "@services/viewport.service";
import { useRoute, useRouter } from "vue-router/composables";
import { ROUTENAMES } from "@models/constants/routes";
import { ciTrackingService } from "@services/ci-tracking.service";
import { settingsService } from "@services/settings.service";

const HeaderStore = getModule(HeaderStoreModule);

export default defineComponent({
  components: {
    "ci-nav-item": NavItem,
    "ci-nav-user": NavUser,
    "ci-nav-search": NavSearch,
    "ci-nav-notifications-popup": NotificationsPopup,
  },
  setup() {
    const { hasUnreadMessages, checkUnreadStatus } = useConversations();
    const { popup, filtered, markAllAsSeen } = useNotifications();
    const route = useRoute();
    const router = useRouter();

    const conversationsItem = CONVERSATIONS_NAV_ITEM;
    const notificationsItem = NOTIFICATIONS_NAV_ITEM;

    const notificationsOpen = computed(() => popup.state.value);
    const unseenNotificationsCount = computed(() => filtered.value.popup.unseen.value);

    const conditionals = computed(() => HeaderStore.conditionals);

    const trackNavItemClick = (item: Navigation.Item | Navigation.Child): void => {
      ciTrackingService.trackClick("ButtonOnMenuBar", item.trackingIdentifier);
    };

    const handleNotificationClick = async () => {
      if (settingsService.hasFlipper("new_start_page") || (!popup.data.value.length && !popup.loading.value)) {
        trackNavItemClick(notificationsItem);
        await router.push({ name: ROUTENAMES.NOTIFICATIONS_INDEX });
      } else {
        if (!viewportService.check(["xs", "sm"]) && route.name !== ROUTENAMES.NOTIFICATIONS_INDEX) {
          markAllAsSeen("popup");
          popup.toggle(true);
        }
      }
    };

    const trackNavClick = (targetIdentifier: string): void => {
      ciTrackingService.trackClick("ButtonOnMenuBar", targetIdentifier);
    };

    const openSearch = async (): Promise<void> => {
      trackNavClick("search");
      HeaderStore.expandSearch();
      await nextTick();
      Emitter.emit("ci::focus::header-search");
    };

    onBeforeMount(() => {
      checkUnreadStatus();
    });

    watch(
      () => route.name,
      () => {
        checkUnreadStatus();
      }
    );

    return {
      hasUnreadMessages,
      checkUnreadStatus,
      conversationsItem,
      notificationsItem,
      conditionals,
      trackNavClick,
      trackNavItemClick,
      openSearch,
      notificationsOpen,
      unseenNotificationsCount,
      handleNotificationClick,
    };
  },
  methods: {
    // still options api because of this.$root
    openLoginModal() {
      this.trackNavClick("login");
      this.$root.$emit("bv::show::modal", `modal-login`);
    },
  },
});
