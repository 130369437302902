import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=28853467&scoped=true&lang=pug"
import script from "./footer.vue?vue&type=script&lang=ts"
export * from "./footer.vue?vue&type=script&lang=ts"
import style0 from "./footer.vue?vue&type=style&index=0&id=28853467&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28853467",
  null
  
)

/* custom blocks */
import block0 from "@locales/de/de.yml?namespace=ui.components.footer?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuild%2Ffrontend%2Fcomponents%2Ffooter%2Ffooter.vue&locale=de&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@locales/en/en.yml?namespace=ui.components.footer?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuild%2Ffrontend%2Fcomponents%2Ffooter%2Ffooter.vue&locale=en&lang=yaml&external"
if (typeof block1 === 'function') block1(component)

export default component.exports