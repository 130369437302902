export declare type BuildURLOptions = {
  path?: string[];
  searchParams?: Indexable<string>;
  anchor?: string;
};

/*
 * Unfortunately, the URL spec does not allow for relative URLs. We do want to use its escaping and search params
 * handling, so we'll do some manual convincing by faking an absolute URL.
 */
const FAKE_BASE_URL = "https://example.com";

export const validateURL = (urlPath: string): void => {
  if (!urlPath.startsWith("/") && !urlPath.startsWith("http")) {
    throw new Error("building relative URLs is neither supported nor recommended");
  }
};

export const buildURL = (urlPath: string, options: BuildURLOptions = {}): string => {
  validateURL(urlPath);

  const url = new URL(urlPath, FAKE_BASE_URL);

  if (options.path) {
    const base = url.pathname !== "/" ? [url.pathname] : [];
    url.pathname = base.concat(options.path.map((segment) => encodeURIComponent(segment))).join("/");
  }
  if (options.anchor) url.hash = options.anchor;
  if (options.searchParams) {
    const encodedSearchParams = new URLSearchParams();
    for (const key in options.searchParams) {
      encodedSearchParams.set(key, options.searchParams[key]);
    }
    // HINT: URLSearchParams interprets + as a space and vice versa
    url.search = encodedSearchParams.toString().replace(/\+/g, "%20");
  }

  return url.toString().replace(FAKE_BASE_URL, "");
};
