import { algoliaService } from "../algolia.service";
import { settingsService } from "../settings.service";
import { BaseApiService } from "./base";
import { SearchResponse } from "@algolia/client-search";

const API_ENDPOINT = "/api/v4/products";

export class ProductsApiService extends BaseApiService implements ProductsApi.Service {
  public investmentResearch: ProductsApi.InvestmentResearchFns = {
    comments: {
      all: (productId) => this.axios.get(`${API_ENDPOINT}/${productId}/investment_research/comments`),
      create: (productId, comment) =>
        this.axios.post(`${API_ENDPOINT}/${productId}/investment_research/comments`, { comment }),
      update: (productId, comment) =>
        this.axios.patch(`${API_ENDPOINT}/${productId}/investment_research/comments/${comment.id}`, { comment }),
      delete: (productId, comment) =>
        this.axios.delete(`${API_ENDPOINT}/${productId}/investment_research/comments/${comment.id}`),
    },
    videos: {
      all: (productId) => this.axios.get(`${API_ENDPOINT}/${productId}/investment_research/videos`),
      create: (productId, video) =>
        this.axios.post(`${API_ENDPOINT}/${productId}/investment_research/videos`, { video }),
      delete: (productId, video) =>
        this.axios.delete(`${API_ENDPOINT}/${productId}/investment_research/videos/${video.id}`),
    },
    files: {
      all: (productId) => this.axios.get(`${API_ENDPOINT}/${productId}/investment_research/files`),
      create: (productId, file) => this.axios.post(`${API_ENDPOINT}/${productId}/investment_research/files`, { file }),
      delete: (productId, file) =>
        this.axios.delete(`${API_ENDPOINT}/${productId}/investment_research/files/${file.id}`),
    },
  };

  public assetflow: ProductsApi.AssetflowFns = {
    currencies: {
      get: (peergroupName) =>
        this.axios.get(`${API_ENDPOINT}/peergroups/currencies`, { params: { peergroup_name: peergroupName } }),
    },
    scatterChartData: {
      get: (currency, peergroupName) =>
        this.axios.get(`${API_ENDPOINT}/inflow_outflows/scatter_chart`, {
          params: { peergroup_name: peergroupName, currency: currency },
        }),
    },
    barChartData: {
      get: (currency, isin) =>
        this.axios.get(`${API_ENDPOINT}/inflow_outflows/bar_chart`, {
          params: { isin: isin, currency: currency },
        }),
    },
  };

  public riskReturn: ProductsApi.RiskReturnFns = {
    products: (params) => this.axios.get(`${API_ENDPOINT}/risk_return/products`, { params }),
    peergroup: (params) => this.axios.get(`${API_ENDPOINT}/risk_return/peergroup`, { params }),
    benchmarks: (params) => this.axios.get(`${API_ENDPOINT}/risk_return/benchmarks`, { params }),
  };

  public all: ProductsApi.AllFn = (type?) => this.axios.get(`${API_ENDPOINT}${type ? "?type=" + type : ""}`);
  public get: ProductsApi.GetFn = (id) => this.axios.get(`${API_ENDPOINT}/${id}`);
  public getAsComparison: ProductsApi.GetAsComparisonFn = (id) =>
    this.axios.get(`${API_ENDPOINT}/${id}`, { params: { type: "comparison" } });
  public getAsPortfolio: ProductsApi.GetAsPortfolioFn = (id) =>
    this.axios.get(`${API_ENDPOINT}/${id}`, { params: { type: "portfolio" } });
  public getAsTile: ProductsApi.GetAsTileFn = (id) =>
    this.axios.get(`${API_ENDPOINT}/${id}`, { params: { type: "tile" } });

  public attachmentsEdit: ProductsApi.AttachmentsEditFn = (id) =>
    this.axios.get(`${API_ENDPOINT}/${id}/attachments_edit`);

  public relatedCompanyProfile: ProductsApi.RelatedCompanyProfileFn = (id) =>
    this.axios.get(`${API_ENDPOINT}/${id}/related_company_profiles`);
  public relatedContents: ProductsApi.RelatedContentsFn = (id) =>
    this.axios.get(`${API_ENDPOINT}/${id}/related_contents`);
  public relatedSocialEvent: ProductsApi.RelatedSocialEventFn = (id) =>
    this.axios.get(`${API_ENDPOINT}/${id}/related_social_events`);

  public getRecommended: ProductsApi.GetRecommendedFn = (id) => this.axios.get(`${API_ENDPOINT}/${id}/recommended`);

  public autocomplete: ProductsApi.AutoCompleteFn = async (query) => {
    const response = await algoliaService.getClient().search([
      {
        indexName: settingsService.options.algolia_index_names.funds,
        query: query,
      } as any,
    ]);

    return (response.results[0] as SearchResponse).hits as unknown as Products.AutoCompleteItem[];
  };

  public rankings: ProductsApi.RankingsFn = (params) => this.axios.get(`${API_ENDPOINT}/rankings`, { params });
  public peergroupRankings: ProductsApi.PeerGroupRankingsFn = (params) =>
    this.axios.get(`${API_ENDPOINT}/rankings/vicinity`, { params });

  public followedProducts: ProductsApi.WatchlistProductsFn = (params) =>
    this.axios.get(`${API_ENDPOINT}/watchlist/followed`, { params });

  public recommendedProducts: ProductsApi.WatchlistProductsFn = (params) =>
    this.axios.get(`${API_ENDPOINT}/watchlist/recommended`, { params });

  public ssoProducts: ProductsApi.WatchlistProductsFn = (params) =>
    this.axios.get(`${API_ENDPOINT}/watchlist/sso`, { params });

  public updateAttachments: ProductsApi.UpdateAttachmentsFn = (
    productId,
    attachments,
    copyToProductIds: number[] = []
  ) =>
    this.axios.patch(`${API_ENDPOINT}/${productId}/attachments`, {
      product: { product_attachments_attributes: attachments },
      copy_to_product_ids: copyToProductIds,
    });
}
